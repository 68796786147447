
@import 'variables';

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

#explain {
  display: block;
  margin: 30px 0 40px 0;
  text-align: center;
  font-family: 'Galada', cursive;
  font-size: 1.9rem;
  color: #4A4A4A;
}

.btn-action .btn-home {
  font-weight: bold;
  background: $primary;
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.2rem;
  color: $strongText;
}

.btn-action{
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin-top: 0%;
  margin-bottom: 3%;
}


#map {
  width: 75%;
  height: 70%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(0, 0, 0, 0.2);
}

.icon-button {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

/**** **** **** **** **** **** **** ****
  > MEDIA QUERIES
  **** **** **** **** **** **** **** ****/

  @media (max-width: 992px) {
    #map{
      width: 100vw;
      height:80vh;
    }
    .btn-action{
      width:100%;
    }

  }
  @media (max-width: 720px){
    .btn-action{
      flex-direction: column;
    }
    .btn-home{
      margin-bottom: 5px;
    }
  }
