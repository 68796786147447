
@import '../variables';

#loading {
  z-index: 999999999;
  display: none;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}

#loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

#loader > p {
  width: 100%;
  text-align: center;
  font-family: 'Titillium Web', cursive;
  font-size: 1.7rem;
  font-weight: bold;
  color: $white;
}
