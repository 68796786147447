
@import 'variables';

.buy-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

#mapbuy {
    width: 80%;
    height: 80%;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(0, 0, 0, 0.2);
    margin-top: 5%;
}


@media (max-width: 992px) {
    #mapbuy{
      width: 100vw;
      margin-top: 1%;
      height:100vh;
    }

  }




